(<template>
  <article class="buyer-job-text">
    <h2 class="buyer-job-text__status-title buyer-job-text__status-title--no-icon">
      <span>{{ title }}</span>
    </h2>
    <div v-if="isAcceptedJob"
         class="buyer-job-text__text-cont">
      <div class="buyer-job-text__text-wrapper">
        <p class="buyer-job-text__title">
          {{ $gettext('What happens now?') }}
        </p>
        <p class="buyer-job-text__text">
          {{ $gettext('We have withdrawn you from this assignment.') }}
        </p>
        <p class="buyer-job-text__text">
          {{ $gettext('Please visit your dashboard to see if there are new assignments available.') }}
        </p>
      </div>
    </div>
  </article>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('OneAssignmentStore', {
        buyerName: (state) => {
          return state.chatDiscussions && state.chatDiscussions.discussion && state.chatDiscussions.discussion.demanderInfo
            ? state.chatDiscussions.discussion.demanderInfo.name : '';
        },
        assignmentObj: (state) => state.storeJobObj || {},
      }),
      title() {
        const template = this.$gettext('We are sorry but %{n} has decided to book someone else for this assignment');
        return this.$gettextInterpolate(template, {n: this.buyerName});
      },
      jobStatus() { return this.assignmentObj.status || ''; },
      isAcceptedJob() { return this.jobStatus === 'accepted'; },
    }
  };
</script>

<style src="@assets/css/buyer-job-text.css" scoped></style>
